import React, {useEffect} from 'react'
import styled from '@emotion/styled'
import {motion, useAnimation} from 'framer-motion'
import {useInView} from 'react-intersection-observer'

import {MEDIA} from '../../utils/constants'

const AboutBio = ({children}) => {
  const controls = useAnimation()

  const {ref, inView} = useInView({
    rootMargin: '-100px 0px',
  })

  useEffect(() => {
    if (inView) controls.start('visible')
  }, [controls, inView])

  return (
    <StyledDiv>
      <motion.div
        style={{position: 'relative'}}
        ref={ref}
        animate={controls}
        initial='hidden'
        transition={{duration: 1.5}}
        variants={{
          visible: {opacity: 1, y: '0px'},
          hidden: {opacity: 0, y: '20px'},
        }}
      >
        {children}
      </motion.div>
    </StyledDiv>
  )
}

export default AboutBio

const StyledDiv = styled.div`
  position: relative;
  margin: 1rem 1rem;
  padding: 5rem 3rem;
  background: whitesmoke;
  border-radius: 5px;
  box-shadow: 3px 3px 15px 2px hsl(0, 0%, 80%);
  font-size: 1.25rem;
  /* width: 100%; */
  /* background: var(--paragraph-bg-color); */
  /* box-shadow: 0px 0px 22px 4px var(--boxshadow-bg-color); */
  /* overflow: hidden; */
  @media (min-width: ${MEDIA.tablet}) {
    padding: 4rem 3rem;
  }
  @media (min-width: ${MEDIA.desktop}) {
    margin: 1rem 2rem;
    padding: 4rem 2rem;
    min-width: 45%;
  } ;
`
