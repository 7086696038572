import React from 'react'
import {StaticQuery, graphql} from 'gatsby'
import styled from '@emotion/styled'
import {motion} from 'framer-motion'

import ReactMarkdown from 'react-markdown/'

import Layout from '../components/mainLayout'
import AboutContent from '../components/AboutContent'
import AboutBio from '../components/AboutBio'
import street from '../assets/street/street3.jpg'

import ContentSection from '../components/ContentSection'

import {MEDIA, LOREM1} from '../utils/constants'
import PageTitle from '../components/PageTitle/PageTitle'

const About = ({data}) => {
  // console.log('page_in_lang[0] ',data.strapiWebpage.page_in_lang[0])

  const defaultTitle = 'About This'
  let title = data?.strapiWebpage?.page_in_lang[0]?.title
    ? data.strapiWebpage.page_in_lang[0].title
    : defaultTitle

  return (
    <Layout>
      <Wrapper>
        <PageTitle text={title} />
        <AboutContent>
          <CenterDiv>
            <ReactMarkdown
              escapeHtml={false}
              source={data.strapiWebpage.page_in_lang[0].centered_content}
            />
          </CenterDiv>
          <ReactMarkdown
            escapeHtml={false}
            source={data.strapiWebpage.page_in_lang[0].content}
          />
        </AboutContent>
        <BioDiv>
          {data.allStrapiBio.edges.map((bio) => (
            <AboutBio key={bio.node.name}>
              <p style={{fontWeight: 'bold'}}>{bio.node.name}</p>
            </AboutBio>
          ))}
        </BioDiv>
      </Wrapper>
    </Layout>
  )
}

export const query = graphql`
  query WebpageAboutQuery {
    strapiWebpage(name: {eq: "about"}) {
      strapiId
      page_in_lang {
        title
        lang
        content
        centered_content
      }
    }
    allStrapiBio {
      edges {
        node {
          role
          name
          content
        }
      }
    }
  }
`

export default About

const Wrapper = styled.div`
  /* padding: 0 1rem; */
  @media (min-width: ${MEDIA.tablet}) {
    /* padding: 0 2rem; */
  }
  @media (min-width: ${MEDIA.desktop}) {
  }
`

const BioDiv = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin: 2rem 0;
  padding: 0;

  @media (min-width: ${MEDIA.tablet}) {
    justify-content: space-evenly;
    padding: 2rem;
  }
  @media (min-width: ${MEDIA.desktop}) {
    flex-direction: row;
    flex-wrap: nowrap;
  }
`

const CenterDiv = styled.div`
  padding: 0 0 2rem 0;
  p {
    text-align: center;
  }
`
